<template>
  <div class="file-content">
    <div class="icon"><font-awesome-icon icon="file-pdf" /></div>
    <div class="body">
      <div class="title" @click="editable == true ? viewFile() : null">
        {{ file.name }}.pdf
        {{ editable }}
      </div>
      <div class="categorie"><strong>Catégorie :</strong> {{ file.type }}</div>
    </div>
    <div class="actions">
      <font-awesome-icon
        icon="times"
        class="delete-icon mr-3"
        @click="deleteFile"
        v-if="this.delete === true && supprimer == true"
      />
      <font-awesome-icon
        icon="download"
        class="download-icon "
        @click="dowloadFile"
        v-if="this.download === true && editable == true"
      />
      <div class="chargement" v-if="loadingDelete">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object
    },
    deleteMethod: {
      type: Function
    },
    delete: {
      type: Boolean,
      default: true
    },
    download: {
      type: Boolean,
      default: false
    },
    downloadAction: {
      type: Function
    },
    item: {
      type: Object,
      default: null
    },
    editable: {
      default: true
    },
    supprimer: {
      default: true
    }
  },
  data() {
    return {
      loadingDelete: false
    };
  },
  methods: {
    async deleteFile() {
      this.loadingDelete = true;
      const response = await this.deleteMethod({
        file: this.file,
        item: this.item
      });
      if (response) {
        this.loadingDelete = false;
      }
    },
    viewFile() {
      window.open(this.file.path, '_blank');
    },
    dowloadFile() {
      this.downloadAction(this.file);
    }
  }
};
</script>

<style scoped lang="scss">
.file-content {
  display: flex;
  position: relative;
  height: auto;
  margin: auto;
  font-size: 12px;
  margin-top: 6px;
  padding: 8px 10px 8px 16px;
  width: 97%;
  border: 1px solid #d8d8d8;
  border-radius: 44px;
  align-items: center;
  justify-content: space-between;
  .icon {
    display: block;
    font-size: 19px;
  }
  .body {
    display: flex;
    width: 85%;
    .title {
      margin-right: 2em;
      display: block;
      overflow: hidden;
      color: #2dabe2;
      cursor: pointer;
      width: 40%;
      &:hover {
        text-decoration: underline;
      }
    }
    .categorie {
      margin: auto;
      color: black;
    }
  }
  .actions {
    display: flex;

    .delete-icon {
      color: #4d4bac;
      font-size: 15px;
      cursor: pointer;
    }
    .download-icon {
      font-size: 20px;
      color: #4d4bac;
      &:hover {
        color: #2dabe2;
        cursor: pointer;
      }
    }
    .chargement {
      margin-left: 9px;
      align-self: center;
      font-size: 10px;
      .spinner-border {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
</style>
